import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { Alert, AlertLink, IconStatusInfo, Switcher, AlertContainer } from '@hse-design/react';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Alert, AlertContainer, AlertLink } from '@hse-design/react'
`}</code></pre>
    <ComponentLinks name={'Alert'} figma={"https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/?node-id=6604%3A14710"} storybook={"/?path=/story/alert--headline"} vueStorybook={"/?path=/story/alert--headline"} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Alert — это закрепленное всплывающее сообщение, предназначенное для информирования пользователей
о требованиях или состояниях системы, в том числе для важных общесистемных уведомлений.`}</p>
    <p>{`Компонент Alert сам по себе не содержит логики позиционирования.`}</p>
    <p>{`Для позиционирования Alert используйте AlertContainer.
Тогда Alert закрепляется на странице внизу экрана (над TapBar и поверх Sidebar) и растягивается на всю ширину экрана.`}</p>
    <p>{`Используйте Alert, если требуется какая-то реакция от пользователя на уведомление.
Отображайте его до тех пор, пока пользователь его сам не закроет, либо
пока не исчезнет источник алерта (например, если пользователь потерял соединение с сетью,
то такой алерт имеет смысл спрятать после восстановления соединения).`}</p>
    <p>{`Для краткосрочных уведомлений рассмотрите компонент Toast (например, для подтверждения действия).`}</p>
    <p>{`В отличие от Toast, код, реализующий отображение конкретных алертов внутри AlertContainer, необходимо реализовать
на уровне продукта самостоятельно.
Это связано с тем, что статус отображения алерта, как правило, привязан к конкретной бизнес-логике приложения.
Рекомендуем создать обёртку над AlertContainer, реализующую отображение конкретных алертов
в зависимости от требуемых условий.`}</p>
    <p>{`Можно использовать алерт в простом варианте без описания,
либо в варианте с подробным описанием (см. примеры ниже).`}</p>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=9288%3A3737' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="%3Fnode-id%3D8267%253A54437" node='?node-id=9288%3A4575' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "alert-без-описания"
    }}>{`Alert без описания`}</h3>
    <p>{`Передайте необходимый заголовок в свойство `}<inlineCode parentName="p">{`title`}</inlineCode></p>
    <Playground __position={3} __code={'<Alert title=\"Просматривая этот сайт, вы соглашаетесь с нашей политикой конфиденциальности\" />'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Alert,
      AlertLink,
      IconStatusInfo,
      Switcher,
      AlertContainer,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Alert title="Просматривая этот сайт, вы соглашаетесь с нашей политикой конфиденциальности" mdxType="Alert" />
    </Playground>
    <h3 {...{
      "id": "alert-с-описанием"
    }}>{`Alert с описанием`}</h3>
    <p>{`Используйте свойства `}<inlineCode parentName="p">{`title`}</inlineCode>{` и `}<inlineCode parentName="p">{`description`}</inlineCode>{` для передачи заголовка и описания`}</p>
    <p>{`Также вы можете передать необходимую иконку в свойство `}<inlineCode parentName="p">{`icon`}</inlineCode></p>
    <Playground __position={4} __code={'<Alert\n  icon={IconStatusInfo}\n  title=\"Просматривая этот сайт, вы соглашаетесь с нашей политикой конфиденциальности\"\n  description=\"В 2005 году в Алерте постоянно проживало 5 человек, не считая военных (около 70 чел.), служащих на канадской военной базе, и сотрудников метеорологической станции. По состоянию на 2011 год, в Алерте не осталось постоянно проживающего населения; тем не менее, всегда присутствует сменяющийся военный и научно-исследовательский персонал.\"\n  actions={\n    <>\n      <AlertLink>Принять</AlertLink>\n      <AlertLink>Отклонить</AlertLink>\n    </>\n  }\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Alert,
      AlertLink,
      IconStatusInfo,
      Switcher,
      AlertContainer,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Alert icon={IconStatusInfo} title="Просматривая этот сайт, вы соглашаетесь с нашей политикой конфиденциальности" description="В 2005 году в Алерте постоянно проживало 5 человек, не считая военных (около 70 чел.), служащих на канадской военной базе, и сотрудников метеорологической станции. По состоянию на 2011 год, в Алерте не осталось постоянно проживающего населения; тем не менее, всегда присутствует сменяющийся военный и научно-исследовательский персонал." actions={<>
                <AlertLink mdxType="AlertLink">
                    Принять
                </AlertLink>
                <AlertLink mdxType="AlertLink">
                    Отклонить
                </AlertLink>
            </>} mdxType="Alert" />
    </Playground>
    <h3 {...{
      "id": "действия-alert"
    }}>{`Действия Alert`}</h3>
    <p>{`Для добавления действий передайте в проп `}<inlineCode parentName="p">{`actions`}</inlineCode>{` нужные ссылки. Используйте компонент `}<inlineCode parentName="p">{`AlertLink`}</inlineCode></p>
    <Playground __position={5} __code={'<Alert\n  title=\"Просматривая этот сайт, вы соглашаетесь с нашей политикой конфиденциальности\"\n  description=\"В 2005 году в Алерте постоянно проживало 5 человек, не считая военных (около 70 чел.), служащих на канадской военной базе, и сотрудников метеорологической станции. По состоянию на 2011 год, в Алерте не осталось постоянно проживающего населения; тем не менее, всегда присутствует сменяющийся военный и научно-исследовательский персонал.\"\n  actions={\n    <>\n      <AlertLink>Принять</AlertLink>\n      <AlertLink>Отклонить</AlertLink>\n    </>\n  }\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Alert,
      AlertLink,
      IconStatusInfo,
      Switcher,
      AlertContainer,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Alert title="Просматривая этот сайт, вы соглашаетесь с нашей политикой конфиденциальности" description="В 2005 году в Алерте постоянно проживало 5 человек, не считая военных (около 70 чел.), служащих на канадской военной базе, и сотрудников метеорологической станции. По состоянию на 2011 год, в Алерте не осталось постоянно проживающего населения; тем не менее, всегда присутствует сменяющийся военный и научно-исследовательский персонал." actions={<>
                <AlertLink mdxType="AlertLink">
                    Принять
                </AlertLink>
                <AlertLink mdxType="AlertLink">
                    Отклонить
                </AlertLink>
            </>} mdxType="Alert" />
    </Playground>
    <h3 {...{
      "id": "alertcontainer"
    }}>{`AlertContainer`}</h3>
    <p>{`Чтобы закрепить Alert в нижней части страницы, используйте AlertContainer.`}</p>
    <p>{`Alert предназначен для долгоживущих сообщений, поэтому AlertContainer не содержит логики автоматического
скрытия элементов по таймауту.
Управляйте набором отображаемых алертов через внешнее состояние, например, Redux или React.Context.`}</p>
    <Playground __position={6} __code={'() => {\n  const [enabled, setEnabled] = useState(false)\n  return (\n    <>\n      <Switcher\n        checked={enabled}\n        onChange={v => setEnabled(v)}\n        label=\"Активировать демо\"\n      />\n      {enabled && (\n        <AlertContainer>\n          <Alert\n            title=\"Мы используем сookies для корректной работы веб-сайта\"\n            actions={({ close }) => {\n              return <AlertLink onClick={close}>Принять</AlertLink>\n            }}\n            onClose={() => setEnabled(false)}\n          />\n        </AlertContainer>\n      )}\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Alert,
      AlertLink,
      IconStatusInfo,
      Switcher,
      AlertContainer,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [enabled, setEnabled] = useState(false);
        return <>
      <Switcher checked={enabled} onChange={v => setEnabled(v)} label="Активировать демо" mdxType="Switcher" />
      {enabled && <AlertContainer mdxType="AlertContainer">
        <Alert title="Мы используем сookies для корректной работы веб-сайта" actions={({
              close
            }) => {
              return <AlertLink onClick={close} mdxType="AlertLink">
                Принять
              </AlertLink>;
            }} onClose={() => setEnabled(false)} mdxType="Alert" />
      </AlertContainer>}
    </>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы-alert"
    }}>{`Пропы Alert`}</h2>
    <Props of={Alert} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой `}<inlineCode parentName="p">{`div`}</inlineCode>{` элемент компонента `}<inlineCode parentName="p">{`BaseAlert`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-alertcontainer"
    }}>{`Пропы AlertContainer`}</h2>
    <Props of={AlertContainer} mdxType="Props" />
    <p>{`Все пропы пробрасываются на корневой `}<inlineCode parentName="p">{`div`}</inlineCode>{` элемент.`}</p>
    <h2 {...{
      "id": "пропы-alertlink"
    }}>{`Пропы AlertLink`}</h2>
    <p>{`Соответствуют компоненту `}<a parentName="p" {...{
        "href": "/src-components-link"
      }}>{`Link`}</a>{`.`}</p>
    <Props of={AlertLink} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент компонента `}<inlineCode parentName="p">{`Link`}</inlineCode>{` в соответствии с `}<inlineCode parentName="p">{`component`}</inlineCode>{` (по умолчанию - `}<inlineCode parentName="p">{`a`}</inlineCode>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      